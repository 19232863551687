import PanelComponent from '@platform/formio/components/panel/Panel';
import { env } from '../../../initRootProps';

export class PanelComponentOverridden extends PanelComponent {
    constructor(...args) {
        super(...args);
    }

    get hasSubmittedFiles() {
        const hasSubmittedFiles = this.component?.components.some((comp) => {
            const panelCompDataItems = this.data[comp.key];

            if (!panelCompDataItems) {
                return false;
            }

            const stringified = JSON.stringify(panelCompDataItems);

            return stringified.includes('subject/file/') && stringified.includes('url');
        });

        return hasSubmittedFiles;
    }

    get subjectId() {
        // Если набору символов предшествует "subjects", выдели нам 2 группы совпадений:
        // Первый участок uri после subjects и всё, что идёт после этого
        // Отфильтруй всё falsy.
        const hrefSlugs = (window.location.href.match(/(?<=\/subjects\/)(.+)(\/.+)?$/) || []).filter(Boolean);

        // Если у какого-то сегмента uri есть запрещенные участки, то верни совпадения
        const uriIsBanned = hrefSlugs.some((slug) => slug.match(/(edit|create)/));

        return !hrefSlugs.length || uriIsBanned ? undefined : hrefSlugs[0];
    }

    appendDownloadAllButton() {
        const subjId = this.subjectId;

        if (!this.component.key.match(/_files$/) || !subjId) {
            return;
        }

        const notDisabled = this.hasSubmittedFiles;

        const downloadAllButton = this.ce(
            'a',
            {
                type: 'text',
                class: 'btn btn-primary',
                // Внутри схлопнутой панели некоторые стили элемента переопределяются. Фиксим инлайн стилем.
                style: `
                        color: #ffffff; 
                        background: #63666a; 
                        border-color: #63666a; 
                        padding: 4px 25px; 
                        margin-left: 83px;
                        opacity: ${notDisabled ? 1 : 0.5};
                        pointer-events: ${notDisabled ? 'all' : 'none'};
                    `,
                target: '_blank',
                href: `${env.apiUrl}/subject/${subjId}/filesAll?panel=${this.component.key}`,
            },
            'Скачать все',
        );

        this.panelBody.appendChild(downloadAllButton);
    }

    build(state) {
        this.component.theme = this.component.theme || 'default';
        let panelClass = 'mb-2 card border ';
        panelClass += `panel panel-${this.component.theme} `;
        panelClass += this.component.customClass;
        this.element = this.ce('div', {
            id: this.id,
            class: panelClass,
        });
        this.element.component = this;

        this.panelBody = this.ce('div', {
            class: 'card-body panel-body drag-container',
        });

        if (this.component.title && !this.component.hideLabel) {
            const heading = this.ce('div', {
                class: `card-header bg-${this.component.theme} panel-heading`,
            });
            this.panelTitle = this.ce('h4', {
                class: 'mb-0 card-title panel-title',
            });

            // добавление индекса (номер панели, задается в настройках)
            const indexSpan = this.ce('span', { class: 'panel-index' });
            if (this.component.useIndex) {
                const componentIndex = this.component.componentIndex;
                indexSpan.textContent = componentIndex;
                if (componentIndex === '') {
                    indexSpan.classList.add('panel-index--empty');
                }
            } else {
                indexSpan.classList.add('panel-index--default');
            }
            this.panelTitle.appendChild(indexSpan);
            this.panelTitle.appendChild(this.text(' '));

            // добавление title
            const titleSpan = this.ce('span', { class: 'panel-title-text' });
            titleSpan.textContent = this.component.title;
            this.panelTitle.appendChild(titleSpan);
            this.panelTitle.appendChild(this.text(' '));

            // оценка критериев (чтобы не перекраивать еще раз build, используется в компонентах экспертизы)
            if (this.expertiseLabelInfo) {
                this.panelTitle.appendChild(this.expertiseLabelInfo());
                this.panelTitle.appendChild(this.text(' '));
            }

            // иконка сворачивания/разворачивания
            if (this.component.collapsible) {
                this.collapseIcon = this.getCollapseIcon();
                this.panelTitle.appendChild(this.collapseIcon);
            }

            this.createTooltip(this.panelTitle);
            heading.appendChild(this.panelTitle);
            this.setCollapseHeader(heading);
            this.element.appendChild(heading);
        } else {
            this.createTooltip(
                this.panelBody,
                this.component,
                `${this.iconClass('question-sign')} text-muted formio-hide-label-panel-tooltip`,
            );
        }

        this.appendDownloadAllButton();

        this.addComponents(null, null, null, state);
        this.element.appendChild(this.panelBody);
        this.setCollapsed();
        this.attachLogic();
    }
}
